import React, {
  Dispatch,
  FC,
  Fragment,
  memo,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  List,
  Menu,
  Popover,
  Row,
  Switch,
  Tooltip,
} from "antd";
import { Formik, FormikValues } from "formik";
import { AgreementKeyTerm } from "../../../models/KeyTerms/agreementKeyTerms.model";
import {
  KeyTermGroups,
  KeyTermGroupsKeyTerm,
} from "../../../models/KeyTerms/keyTermGroups.model";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import InputField from "../../../shared/components/InputField";
//@ts-ignore
import SilverPinIcon from "../../../assets/icons/pinned-tag-grey.svg";
//@ts-ignore
import BluePinIcon from "../../../assets/icons/pinned-tag-blue.svg";
//@ts-ignore
import GreenPinIcon from "../../../assets/icons/pinned-tag-green.svg";
//@ts-ignore
import RedPinIcon from "../../../assets/icons/pinned-tag-red.svg";
import { KeyTerms } from "../../../models/KeyTerms/keyTerms.model";
import { KeyTermTypes } from "../../../enums/keyTermsList";
import DropdownField from "../../../shared/components/DropdownField";
import AgreementKeyTermService, {
  KeyTermList,
} from "../../../services/AgreementService/agreementKeyTerm.service";
import { Agreement } from "../../../models/Agreements/agreement.model";
import DatePickerComponent from "../../../shared/components/DatePickerComponent";
import moment from "moment";
import { fundBaseCurrencies } from "../../../shared/utils/fundDefinitions";
import { idText } from "typescript";
import { SelectValue } from "antd/lib/select";
import { HighlightArea } from "@react-pdf-viewer/highlight";
import PDFContainer from "../../../store/container/pdfContainer";
import { PDFReducerProps } from "../../../store/reducers/pdfReducer";
import { Note } from "../../Document/PDFPin";
//@ts-ignore
import debounce from "debounce";
import { HLAgreementService } from "../../../services/AgreementService/agreement.service";

interface KeyTermListItemProps extends PDFReducerProps {
  openTab?: number;
  loading: boolean;
  isAdmin?: boolean;
  keyTermSelected: Function;
  setAddTag: Dispatch<SetStateAction<boolean>>;
  setSelectedKeyTermGroupId: Dispatch<SetStateAction<string>>;
  agreement?: Agreement;
  selectedKeyTermGroupId: string;
  openKeyTermGroupId: string;
  keyTerms: KeyTerms[];
  openKeyTermId: string;
  keyTermGroups: KeyTermGroups[];
  setOpenKeyTermGroupId: Dispatch<SetStateAction<string | undefined>>;
  setRemoveTag: Dispatch<SetStateAction<string>>;
  jumpToHighlightArea: (area: HighlightArea) => void;
  keyTermList: KeyTermList;
  setKeyTermList: (keyTermList?: KeyTermList | undefined) => void;
  handleKeyTermGroups: () => void;
  activeTabId: string;
  handleTabChange:(id:string,keyTerm:KeyTerms)=>void;
  redirectedKeyTerm:KeyTerms;
  isDocumentLoaded?:boolean
}

const booleanOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
const calendarDaysOptions = [
  { label: "Business Days", value: "Business days" },
  { label: "Calendar Days", value: "Calendar days" },
  { label: "Calendar Weeks", value: "Calendar weeks" },
  { label: "Calendar Months", value: "Calendar months" },
  { label: "N/A", value: "N/A" },
];

const KeyTermListItem: FC<KeyTermListItemProps> = (props) => {
  const { Panel } = Collapse;
  const {
    // loading,
    isAdmin,
    keyTerms,
    agreement,
    keyTermGroups,
    setKeyTermGroups,
    keyTermSelected,
    setAddTag,
    keyTermList,
    openKeyTermGroupId,
    setOpenKeyTermGroupId,
    setSelectedKeyTermGroupId,
    jumpToHighlightArea,
    openKeyTermId,
    setKeyTermList,
    openTab,
    notes,
    setNotes,
    handleKeyTermGroups,
    activeTabId,
    handleTabChange,
    redirectedKeyTerm,
    isDocumentLoaded
  } = props;
  const [formMode, setFormMode] = useState<number | null>(null);
  const { createAgreementKeyTerms, updateAgreementKeyTerms, loading } =
    AgreementKeyTermService();
    
    useEffect(() => {
     isDocumentLoaded && redirectedKeyTerm?.id && jumpToClick(redirectedKeyTerm)
    }, [isDocumentLoaded]);
    
  useEffect(() => {
    if (openKeyTermGroupId) {
      handleFetchBasedOnUser(openKeyTermGroupId);
    }
  }, [openKeyTermGroupId]);
  
  useEffect(() => {
    keyTermSelected(keyTerms?.find((ele) => ele.id === +openKeyTermId));
  }, [keyTerms]);

  useEffect(() => {
    if (isAdmin) {
      setFormMode(+openKeyTermId);
    }
  }, [openKeyTermId]);
  
  useEffect(() => {
   if(activeTabId) setFormMode(null)
  }, [activeTabId]);


  const animatePin = (pin: HTMLImageElement) => {
    let height = pin?.height,
      width = pin?.width;
    let increase = true,
      count = 0;
    let interval = setInterval(() => {
      if (increase) {
        pin.height += 1;
        pin.width += 1;
      } else {
        pin.height -= 1;
        pin.width -= 1;
      }
      count++;
      if (count === 3) {
        if (pin?.height === height && pin?.width === width && !interval) {
          clearInterval(interval);
        }
        increase = !increase;
        count = 0;
      }

      setTimeout(() => {
        clearInterval(interval);
        pin.height = height;
        pin.width = width;
      }, 3000);
    }, 100);
  };
  const handleRemoveTag = async (keyTerm: KeyTerms, group: KeyTermGroups) => {
    const newKeyTerm = {
      ...keyTerm,
      agreementKeyTerm: keyTerm.agreementKeyTerm?.length
        ? [
            {
              ...keyTerm.agreementKeyTerm[0],
              taggedPoint: undefined,
            },
          ]
        : [],
    };

    const updatedKeyTerm = await updateAgreementKeyTerms(
      newKeyTerm?.agreementKeyTerm[0],
      activeTabId,
      agreement?.id?.toString() ?? "",
      "removePin"
    );

    if (updatedKeyTerm) {
      const updatedNotes = notes?.filter((note) => {
        if (keyTerm?.agreementKeyTerm?.length) {
          return keyTerm.agreementKeyTerm[0]?.id !== note.id;
        }
        return true;
      });

      setNotes(updatedNotes as Note[]);
      const updatedKeyTermList = {
        ...keyTermList,
        [group.id ?? ""]: Array.isArray(keyTermList[group.id ?? ""])
          ? keyTermList[group?.id ?? ""]?.map((kt) => {
              if (keyTerm.id === kt.id) {
                return newKeyTerm;
              }
              return kt;
            })
          : [],
      };
      const updatedKeyTermGroups = keyTermGroups?.map((group) => {
        if (group.id !== undefined && group.id in updatedKeyTermList) {
          group.agreementKeyTerms = updatedKeyTermList[group.id];
        }
        return group;
      });
      setKeyTermList(updatedKeyTermList);
      setKeyTermGroups(updatedKeyTermGroups);
    }
  };
  useEffect(() => {
    if (openTab) {
      for (let [key, keyTerms] of Object.entries(keyTermList)) {
        if (keyTerms) {
          for (let keyTerm of keyTerms) {
            if (
              keyTerm?.agreementKeyTerm &&
              keyTerm?.agreementKeyTerm?.length &&
              keyTerm?.agreementKeyTerm[0].id === openTab
            ) {
              setOpenKeyTermGroupId(key);
              setTimeout(() => {
                const content = document.getElementById(openTab.toString());
                if (content) {
                  content?.scrollIntoView();
                  const pin = content.children[0];
                  animatePin(pin as HTMLImageElement);
                }
              }, 500);
              return;
            }
          }
        }
      }
    }
  }, [openTab]);

  const handleFetchBasedOnUser = (id?: string | string[]) => {
    if (typeof id === "string" || !id) {
      setOpenKeyTermGroupId(id);
    }
  };

  const updateKeyTermList = (keyTerm?: AgreementKeyTerm, groupId?: number) => {
    if (keyTerm && groupId) {
      const newKeyTermGroup: AgreementKeyTerm[] = [keyTerm];
      const filteredGroup: KeyTermGroups[] = keyTermGroups?.map((group) =>
        group.id === groupId
          ? ({
              ...group,
              agreementKeyTerms: group.agreementKeyTerms?.map(
                (specificKeyTerm) =>
                  specificKeyTerm.id === keyTerm.keyTermId
                    ? ({
                        ...specificKeyTerm,
                        agreementKeyTerm: newKeyTermGroup,
                      } as KeyTermGroupsKeyTerm)
                    : specificKeyTerm
              ),
            } as KeyTermGroups)
          : group
      );
      const group = keyTermList[groupId]?.map((k) => {
        if (k.id === keyTerm.keyTermId) {
          return {
            ...k,
            agreementKeyTerm: [keyTerm],
          };
        }
        return k;
      });
      setKeyTermList({
        ...keyTermList,

        [groupId]: group,
      });
      setKeyTermGroups(filteredGroup);
    }
  };

  const handleKeyTermCreation = async (
    values: AgreementKeyTerm,
    groupId?: number,
    itemId?: number
  ) => {
    if (!groupId) return;
    try {
      const keyTerm = await createAgreementKeyTerms(
        values,
        agreement?.id?.toString() ?? "",
        activeTabId
      );
      updateKeyTermList(keyTerm, groupId);
      setFormMode(null);
      handleFetchBasedOnUser(groupId.toString());

      if (keyTerm) {
        const filteredGroup = keyTermGroups?.map((group) =>
          group?.id === groupId
            ? {
                ...group,
                agreementKeyTerms: group?.["agreementKeyTerms"]?.map((term) =>
                  term?.id === itemId
                    ? { ...term, agreementKeyTerm: [keyTerm], toggleable: true }
                    : term
                ),
              }
            : group
        );

        const specificGroup = filteredGroup?.find(
          (groupData) => groupData?.id === groupId
        );
        setKeyTermGroups(filteredGroup);
        setKeyTermList({
          ...keyTermList,
          [Number(groupId)]: specificGroup?.agreementKeyTerms,
        });
      }
    } catch (error) {}
  };

  const handleKeyTermUpdation = async (
    values: AgreementKeyTerm,
    groupId?: number
  ) => {
    if (!groupId) return;
    try {
      const keyTerm = await updateAgreementKeyTerms(
        values,
        activeTabId,
        agreement?.id?.toString() ?? ""
      );
      setFormMode(null);
      handleFetchBasedOnUser(groupId.toString());
      updateKeyTermList(keyTerm, groupId);

      return keyTerm;
    } catch (error) {
      return false;
    }
  };

  const enterFormMode = (keyTerm: KeyTerms) => {
    keyTermSelected(keyTerm);
    if (!isAdmin) {
      return;
    }
    setFormMode(keyTerm?.id ?? null);
  };
  const valueFormatter = (value?: string, type?: KeyTermTypes) => {
    if (type === KeyTermTypes.CURRENCY) {
      return value?.toUpperCase();
    }
    if (type === KeyTermTypes.BOOLEAN) {
      return value
        ?.charAt(0)
        .toUpperCase()
        .concat(value?.slice(1).toLowerCase());
    }
    if (type === KeyTermTypes.DATE) {
      return moment(value).format("DD, MMM YYYY");
    }
    if (type === KeyTermTypes.TIME) {
      return moment(value).format("hh:mm:ss");
    }
    if (type === KeyTermTypes.PERCENTAGE) {
      return value + "%";
    }
    return value;
  };

  const updateAmendments = async (
    index: number,
    agreementKeyTermId?: number,
    groupId?: number
  ) => {
    if (agreementKeyTermId && groupId) {
      const keyTermItems = [...(keyTermList[groupId] ?? [])];
      if (keyTermItems) {
        const agreementKeyTerm = [
          {
            ...keyTermItems[index].agreementKeyTerm[0],
          },
        ];
        await updateAgreementKeyTerms(
          agreementKeyTerm[0],
          activeTabId,
          agreement?.id?.toString() ?? ""
        );
        keyTermItems.splice(index, 1, {
          ...keyTermItems[index],
          agreementKeyTerm,
        });
        setKeyTermList({
          ...keyTermList,
          [groupId]: keyTermItems,
        });
      }
    }
  };

  const InputGenerator = (
    type?: KeyTermTypes,
    value?: string,
    onChange?: (val: SelectValue) => void
  ) => {
    if (!onChange) return;
    const name = "value";
    if (type === KeyTermTypes.BOOLEAN || type === KeyTermTypes.CURRENCY) {
      return (
        <DropdownField
          options={
            type === KeyTermTypes.BOOLEAN ? booleanOptions : fundBaseCurrencies
          }
          name={name}
          mode="single"
          placeholder={type === KeyTermTypes.BOOLEAN ? "Yes or No" : "Currency"}
          showSearch={false}
          value={value}
          onChange={onChange}
          allowClear={true}
        />
      );
    }
    if (type === KeyTermTypes.PERCENTAGE) {
      return (
        <InputField
          type="number"
          name={name}
          placeholder="Percentage"
          value={parseInt(value ?? "0") <= 100 ? value : value?.substr(0, 2)}
          onChange={(e) => onChange(e.target.value)}
        />
      );
    }
    if (type === KeyTermTypes.DAY_COUNT) {
      const values = value?.split(" ") || ["", ""];
      return (
        <Row>
          <Col sm={12}>
            <InputField
              type="number"
              name={name}
              placeholder="Count"
              value={values[0]}
              onChange={(e) => onChange(e.target.value + " " + values[1])}
            />
          </Col>
          <Col sm={12}>
            <DropdownField
              options={calendarDaysOptions}
              name={name}
              mode="single"
              placeholder={"Select Count Type"}
              showSearch={false}
              value={values[1] + (values[2] ? " " + values[2] : "")}
              onChange={(val) => onChange(values[0] + " " + val)}
            />
          </Col>
        </Row>
      );
    }
    if (type === KeyTermTypes.DATE || type === KeyTermTypes.TIME) {
      return (
        <DatePickerComponent
          picker={type}
          name={name}
          placeholder={type === KeyTermTypes.DATE ? "Date" : "Time"}
          value={value ? moment(value) : undefined}
          onChange={(momentDate, dateString) =>
            onChange(moment(momentDate).format())
          }
        />
      );
    }

    return (
      <InputField
        type="text"
        name={name}
        placeholder="Type Key Term Entry"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    );
  };

  const TerminationDropDown = (
    keyTerm: KeyTerms,
    group: KeyTermGroups,
    index: number
  ) => {
    return (
      <Menu
        className={`pin-dropdown ${
          keyTerm?.agreementKeyTerm[0]?.referencePoint
            ? ""
            : "invisible-content"
        }`}
      >
        {!keyTerm?.agreementKeyTerm[0]?.taggedPoint && (
          <Menu.Item
            key="1"
            onClick={() => {
              setAddTag(true);
              keyTermSelected(keyTerm);
            }}
            className="blue-pin"
          >
            {" "}
            <img src={BluePinIcon} alt="" /> Add Tag
          </Menu.Item>
        )}
        {/* {agreement?.hasAmendments &&
          !keyTerm?.agreementKeyTerm[0]?.isAmendment &&
          !keyTerm?.agreementKeyTerm[0]?.taggedPoint && (
            <Menu.Item
              key="2"
              onClick={() => {
                const agreementKeyTermId = keyTerm.agreementKeyTerm[0]?.id;
                updateAmendments(index, agreementKeyTermId, group?.id);
              }}
              className="green-pin"
            >
              {" "}
              <img src={GreenPinIcon} alt="" /> See Amendments
            </Menu.Item>
          )} */}
        {keyTerm?.agreementKeyTerm[0]?.taggedPoint && (
          <Menu.Item
            key="3"
            onClick={(click) => {
              click.domEvent.stopPropagation();
              handleRemoveTag(keyTerm, group);
            }}
            className="red-pin"
          >
            {" "}
            <img src={RedPinIcon} alt="" /> Remove{" "}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  if (!Object.keys(keyTermList).length) {
    return null;
  }

  const animateElement = (pin: any) => {
    animatePin(pin);
  };

  const jumpToClick = (item: KeyTerms) => {
    if (item.agreementKeyTerm.length && item.agreementKeyTerm[0]?.taggedPoint) {
      const pins = document.getElementsByClassName(
        `${item?.agreementKeyTerm[0]?.id?.toString()}-img`
      );
      Object.values(pins).map(animateElement);
      jumpToHighlightArea(
        item?.agreementKeyTerm[0]?.taggedPoint as HighlightArea
      );
    }
  };
  
  const getClassName = (item: KeyTerms) => {
    if (item?.agreementKeyTerm?.length) {
      return item.agreementKeyTerm[0]?.id?.toString();
    }
    return "0";
  };

  const handleOutlinedStatuschange = (
    checked: boolean,
    item: KeyTerms,
    groupId: number,
    agreementKey: number
  ) => {
    HLAgreementService.updateOutlinedStatus(
      String(agreement?.id),
      String(item?.agreementKeyTerm[0]?.id),
      checked,
      () => {
        const data = keyTermGroups.map((group) => ({
          ...group,
          agreementKeyTerms:
            group.id === groupId
              ? group.agreementKeyTerms?.map((term) => ({
                  ...term,
                  agreementKeyTerm:
                    term.id === item?.id
                      ? [{ ...term?.agreementKeyTerm?.[0], outlier: checked }]
                      : term?.agreementKeyTerm,
                }))
              : group.agreementKeyTerms,
        }));
        const specificGroup = data?.find((group) => group?.id === groupId);
        setKeyTermGroups(data);
        setKeyTermList({
          ...keyTermList,

          [groupId]: specificGroup?.agreementKeyTerms,
        });
      },
      () => {},
      () => {}
    );
  };
  return (
    <Collapse
      bordered={false}
      accordion
      expandIconPosition="right"
      collapsible={loading ? "disabled" : undefined}
      onChange={handleFetchBasedOnUser}
      activeKey={openKeyTermGroupId}
    >
      {keyTermGroups?.map((group, i) => {
      
        return (
          <Panel
            header={group?.name}
            id={group?.id?.toString()}
            key={group?.id ?? 0}
          >
            <List
              size="large"
              bordered
              dataSource={keyTermList[group.id ?? 0]}
              renderItem={(item, i) => {
                const isAgreementAmendment= item?.agreementKeyTerm[0]?.amendmentId &&  item?.agreementKeyTerm[0]?.agreementId
                return (
                  <List.Item
                    onClick={() =>{ !isAgreementAmendment && jumpToClick(item)} }
                    className={`${formMode === item?.id ? "selected" : ""} ${
                      openTab === item?.agreementKeyTerm[0]?.keyTermId
                    }`}
                    id={getClassName(item)}
                  >
                    {(isAdmin || item?.agreementKeyTerm[0]?.taggedPoint) && (
                      <Dropdown
                        disabled={!isAdmin}
                        className="pin-menu"
                        overlay={() => TerminationDropDown(item, group, i)}
                        trigger={["click"]}
                      >
                     
                        { isAgreementAmendment? <Popover placement="left" content={item?.agreementKeyTerm[0]?.amendmentName} > 
                         <img
                          className="pin-menu--icon"
                          src={GreenPinIcon}
                          alt=""
                          onClick={()=>handleTabChange(String(item?.agreementKeyTerm[0]?.amendmentId),item)}
                       
                        />
                         </Popover> : 
                          <img
                        className="pin-menu--icon"
                        src={
                           item?.agreementKeyTerm[0]?.taggedPoint
                            ? BluePinIcon
                            : SilverPinIcon
                        }
                        alt=""
                     
                      /> 
                         }
                         
                      </Dropdown>
                    )}
                    <p
                      className="cursor-pointer"
                      onClick={() => jumpToClick(item)}
                    >
                      <div className="text">
                        {i + 1}. {item?.name}
                      </div>

                      <Tooltip
                        placement="topLeft"
                        title={
                          !item?.toggleable &&
                          "Key terms cannot be toggled if values are empty"
                        }
                      >
                        <Switch
                          checked={item?.agreementKeyTerm[0]?.outlier}
                          disabled={!item?.toggleable}
                          className="outliner-switch"
                          onChange={(value) =>
                            handleOutlinedStatuschange(
                              value,
                              item,
                              Number(group?.id),
                              Number(item?.agreementKeyTerm[0])
                            )
                          }
                        />
                      </Tooltip>
                    </p>
                    {formMode === item?.id ? (
                      <Formik
                        initialValues={
                          item?.agreementKeyTerm[0] || new AgreementKeyTerm()
                        }
                        onSubmit={async (values) => {
                          
                          if (item?.agreementKeyTerm[0]?.keyTermId)
                          await handleKeyTermUpdation({ ...values, value: values?.value || "" }, group?.id);

                      else  {
                            await handleKeyTermCreation(
                              { ...values, keyTermId: formMode },
                              group?.id,
                              item?.id
                            );
                          }
                        }}
                      >
                        {({ values, submitForm, setFieldValue }) => (
                          <Form onKeyDown={(event) => {}}>
                            <Row>
                              <Col span={24}>
                                {InputGenerator(
                                  item?.keyTermType,
                                  values.value,
                                  (val) => setFieldValue("value", val)
                                )}
                              </Col>
                              <Col span={24}>
                                <InputField
                                  type="text"
                                  name="referencePoint"
                                  placeholder="Section"
                                />
                              </Col>
                              <Col span={24}>
                                <Row justify="end">
                                  <Col className="mr-2">
                                    <ButtonComponent
                                      onClick={() => setFormMode(null)}
                                    >
                                      Cancel
                                    </ButtonComponent>
                                  </Col>
                                  <Col>
                                    <div className="btn__wrapper">
                                      <ButtonComponent
                                        type={"primary"}
                                        onClick={submitForm}
                                        displayType="space-between"
                                        loading={loading}
                                        // disabled={!values.value || !values.referencePoint}
                                      >
                                        Save
                                      </ButtonComponent>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    ) : !!item?.agreementKeyTerm[0]?.value ||
                      !!item?.agreementKeyTerm[0]?.referencePoint ? (
                      <div>
                        <p
                          className={`keyterm--helpers ${item?.keyTermType}`}
                          onClick={() => enterFormMode(item || null)}
                        >
                          {valueFormatter(
                            item?.agreementKeyTerm[0]?.value,
                            item?.keyTermType
                          )}
                        </p>
                        <p
                          className="keyterm--helpers"
                          onClick={() => enterFormMode(item || null)}
                        >
                          {item?.agreementKeyTerm[0]?.referencePoint}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <p
                          className="keyterm--helpers"
                          onClick={() => enterFormMode(item || null)}
                        >
                          {isAdmin ? "Add Key Term Entry" : " "}{" "}
                        </p>
                        <p
                          className="keyterm--helpers"
                          onClick={() => enterFormMode(item || null)}
                        >
                          {isAdmin ? "Add Section" : " "}{" "}
                        </p>
                      </div>
                    )}
                  </List.Item>
                );
              }}
            />
          </Panel>
        );
      })}
    </Collapse>
  );
};

export default memo(PDFContainer(KeyTermListItem));

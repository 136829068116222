import { Col, Input, List, Radio, Row, Tabs } from "antd";
import React, { FC, Fragment, useEffect, useState } from "react";
import { Collapse } from "antd";
import "./documentDetails.scss";
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import ButtonComponent from "./../../shared/components/ButtonComponent";
import AgreementKeyTermService from "../../services/AgreementService/agreementKeyTerm.service";
import { Agreement, Amendment } from "../../models/Agreements/agreement.model";
import KeyTermListItem from "./KeyTermListItem";
import { KeyTerms } from "../../models/KeyTerms/keyTerms.model";
import { ConfirmationAlert } from "../../shared/components/ConfirmationAlert";
import { UIModal } from "../../shared/components/UIModal";
import moment from "moment";
import { SearchInput } from "../../shared/components/SearchInput";
import { TableComponent } from "../../shared/components/TableComponent";
import { HLAgreementService } from "../../services/AgreementService/agreement.service";
import Document from "../Document";
import usePDFPin from "../Document/PDFPin";
import PDFSearch from "../Document/PDFSearch";
import { searchPlugin } from "@react-pdf-viewer/search";
import PDFContainer from "../../store/container/pdfContainer";
import { PDFReducerProps } from "../../store/reducers/pdfReducer";
import { NotificationTypes } from "../../enums/notificationTypes";

interface DocumentDetailProps extends PDFReducerProps {}

const DocumentDetails: FC<DocumentDetailProps> = (props) => {
  const {
    agreement,
    setAgreement,
    keyTermGroups,
    setKeyTermGroups,
    keyTerms,
    setKeyTerms,
    setKeyTermList,
    keyTermList,
    setNotes,
    notes,
  } = props;

  const params = new URLSearchParams(window.location.search);

  const agreementId = parseInt(params.get("agreement") ?? "");

  const isAdmin = !!parseInt(params.get("isAdmin") ?? "");

  const { Panel } = Collapse;
  const [showDiscard, setShowDiscard] = useState<boolean>(false);
  const [addTag, setAddTag] = useState<boolean>(false);
  const [showImportKT, setShowImportKT] = useState<boolean>(false);
  const [ktSearchText, setKtSearchText] = useState<string>();
  const [selectedKeyTerm, setSelectedKeyTerm] = useState<KeyTerms>();
  const [selectedKeyTermGroupId, setSelectedKeyTermGroupId] =
    useState<string>("");
  const [openKeyTermGroupId, setOpenKeyTermGroupId] = useState<string>("");
  const [removeTag, setRemoveTag] = useState<string>("");
  const [openKeyTermId, setOpenKeyTermId] = useState<string>("");
  const [selectedKtAgreementId, setSelectedKtAgreementId] = useState<number>();
  const [ktImportableAgreements, setKtImportableAgreements] = useState<
    Agreement[]
  >([]);
  const[redirectedKeyTerm,setRedirectedKeyTerm]=useState<KeyTerms>()
  const [activeTabKey, setActiveTabKey] = useState<string>("1");
  const [openTab, setOpenTab] = useState<number>();
  const[isDocumentLoaded,setDocumentLoaded]=useState(false)
  const TabPane = Tabs.TabPane;
  const handleCloseAddTag = () => {
    setAddTag(false);
  };

  const {
    loading,
    keyTermNotes,
    deleteAgreementKeyTerms,
    fetchAgreementHFKeyTerms,
    fetchAgreementKeyTermGroups,
    importKeyTermsFromAgreement,
    updateAgreementKeyTermsCompleted,
  } = AgreementKeyTermService(setKeyTermGroups, setKeyTerms, setKeyTermList);

  const searchPluginInstance = searchPlugin();

  const openSelectTab = (noteId: number) => {
    setOpenTab(noteId);
  };

  const { highlightPluginInstance, jumpToHighlightArea } = usePDFPin(
    notes ?? [],
    addTag,
    handleCloseAddTag,
    keyTermNotes,
    openSelectTab,
    keyTermList,
    setKeyTermList,
    selectedKeyTerm,
    setNotes,
    keyTermGroups,
    setKeyTermGroups,
    activeTabKey
  );

  const getAgreementKeyTermGroups = () => {
    fetchAgreementKeyTermGroups(agreementId?.toString(), isAdmin);
  };

  const [amendments, setAmendments] = useState<Amendment[]>();
  const [tableLoading, setTableLoading] = useState(true);
  const [successVisible, setSuccessVisibility] = useState(false);
  const [amendmentNames, setAmendmentNames] = useState<string[]>([]);
  const [amendmentId, setAmendmentId] = useState<string[]>([]);

  useEffect(() => {
    if (agreementId) {
      fetchAmendmentTabs(agreementId.toString());
      HLAgreementService.getAgreement(
        agreementId,
        async (agreement) => {
          setAgreement(agreement);
          await getAgreementKeyTermGroups();
          await fetchImportableKTAgreements(agreement?.agreementTypeId);
        },
        () => {},
        () => {}
      );
    }
  }, [agreementId]);

  useEffect(() => {
    fetchImportableKTAgreements();
  }, [ktSearchText]);

  const fetchHLKeyTerms = async (groupId: string) => {
    setKeyTerms([]);
    if (!groupId) {
      return;
    }

    // await fetchAgreementKeyTerms(agreement?.id?.toString() || "", {
    //   key_term_group_id: groupId,
    // });
  };
  const fetchHFKeyTerms = async (groupId: string) => {
    setKeyTerms([]);
    if (!groupId) {
      return;
    }
    await fetchAgreementHFKeyTerms(agreement?.id?.toString() || "", {
      key_term_group_id: groupId,
    });
  };

  const discardAgreement = async (reload?: boolean) => {
    try {
      const discarded = await deleteAgreementKeyTerms(
        agreement?.id?.toString() || ""
      );
      if (reload) {
        fetchAgreementKeyTermGroups(agreement?.id?.toString() || "", isAdmin);
      }
      if (discarded) {
        setNotes([]);
      }
      setShowDiscard(false);
      return true;
    } catch (error) {}
  };
  const markAgreementAsCompleted = async () => {
    try {
      await updateAgreementKeyTermsCompleted(agreement?.id?.toString() || "");
    } catch (error) {}
  };

  const handleSearchImportableKTAgreements = (searchString: string) => {
    setKtSearchText(searchString);
    // fetchImportableKTAgreements();
  };

  //KT
  const handleImportKTAgreements = async () => {
    try {
      await discardAgreement();
      await importKeyTermsFromAgreement(agreement?.id?.toString() || "", {
        import_from: String(selectedKtAgreementId),
      });
      await fetchAgreementKeyTermGroups(
        agreement?.id?.toString() || "",
        isAdmin
      );
      await fetchImportableKTAgreements();
      setShowImportKT(false);
    } catch (error) {}
  };

  const fetchImportableKTAgreements = (agreementTypeId?: string) => {
    HLAgreementService.fetchAgreements(
      {
        search_text: ktSearchText,
        is_complete: true,
        agreement_type_ids: [
          agreementTypeId ?? String(agreement?.agreementTypeId),
        ],
      },
      (agreements: Agreement[]) => {
        setKtImportableAgreements(agreements);
      },
      (error: any) => {},
      () => {},
      isAdmin
    );
  };

  const fetchAmendmentTabs = (agreementTypeId?: string) => {
    HLAgreementService.fetchAmendments(
      agreementId.toString(),
      { is_archived: false },
      (response: Amendment[]) => {
        const amendmentName = response.map((amendment) => amendment.name);
        const amendmentId = response.map((amendment) => amendment.id);
        const amendmentURL = response.map((amendment) => amendment.documentUrl);
        setAmendments(response);
      },
      (error: any) => {},
      () => {}
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (agreementId) {
        try {
          setTableLoading(true);
          await HLAgreementService.getAgreement(
            agreementId,
            async (agreementData) => {
              setAgreement(agreementData);
              await getAgreementKeyTermGroups();
              await fetchImportableKTAgreements(agreementData?.agreementTypeId);
            },
            () => {},
            () => {}
          );
        } finally {
          setTableLoading(false);
        }
      }
    };

    fetchData();
  }, [agreementId]);

  const handleKeyTermSelection = (keyItem: KeyTerms) => {
    setSelectedKeyTerm(keyItem);
  };
  useEffect(() => {
    setOpenTab(Number(openKeyTermGroupId))
  },[openKeyTermGroupId])

  const keyTermToolbarGenerator = () => {
    return (
      <KeyTermListItem
        jumpToHighlightArea={jumpToHighlightArea}
        agreement={agreement}
        loading={loading}
        keyTerms={keyTerms as any}
        isAdmin={isAdmin}
        keyTermSelected={handleKeyTermSelection}
        setAddTag={setAddTag}
        selectedKeyTermGroupId={String(selectedKeyTermGroupId)}
        setSelectedKeyTermGroupId={setSelectedKeyTermGroupId}
        openKeyTermGroupId={String(openKeyTermGroupId)}
        setOpenKeyTermGroupId={setOpenKeyTermGroupId}
        openKeyTermId={String(openKeyTermId)}
        setRemoveTag={setRemoveTag}
        keyTermList={keyTermList as any}
        setKeyTermList={setKeyTermList}
        openTab={openTab}
        handleKeyTermGroups={getAgreementKeyTermGroups}
        activeTabId={activeTabKey}
        handleTabChange={handleTabChange}
        redirectedKeyTerm={redirectedKeyTerm}
        isDocumentLoaded={isDocumentLoaded}
      />
    );
  };

  const handleTabChange = (key: string,keyTerm?:KeyTerms) => {
    setRedirectedKeyTerm(new KeyTerms())
    setNotes([]);
    !keyTerm?.id && setOpenKeyTermGroupId("")
    setDocumentLoaded(false)
    setKeyTermGroups([]);
    setActiveTabKey(key);
    if (amendments && key !== "1") {
      fetchAgreementKeyTermGroups(agreementId?.toString(), isAdmin, key);
    } else {
      fetchAgreementKeyTermGroups(agreementId?.toString(), isAdmin);
    }
    if(keyTerm) setRedirectedKeyTerm(keyTerm)
  };

  return (
    <Row className="document-details__container">
      <Col span={24} className="document-details--header">
        <Collapse
          bordered={false}
          expandIcon={() => <Fragment />}
          accordion={false}
          className="site-collapse-custom-collapse"
        >
          <Panel
            key="1"
            header={
              <span style={{ margin: "0 auto 0 13rem" }}>
                {agreement?.name}{" "}
              </span>
            }
            extra="Details"
          >
            <Row wrap={false}>
              {agreement?.companyName && (
                <Col className="panel-details" span={2.5}>
                  <p className="panel-details--title">HFund</p>
                  <p className="panel-details--value">
                    {agreement.companyName}
                  </p>
                </Col>
              )}
              {agreement?.fundName && (
                <Col className="panel-details" span={2.5}>
                  <p className="panel-details--title">Fund Name</p>
                  <p className="panel-details--value">{agreement?.fundName}</p>
                </Col>
              )}
              {agreement?.cpName && (
                <Col className="panel-details" span={2}>
                  <p className="panel-details--title">CP Name</p>
                  <p className="panel-details--value">{agreement?.cpName}</p>
                </Col>
              )}
              {(agreement?.agreementType?.name ||
                agreement?.agreementTypeName) && (
                <Col className="panel-details" span={1}>
                  <p className="panel-details--title">Type</p>
                  <p className="panel-details--value">
                    {agreement?.agreementType?.name ||
                      agreement?.agreementTypeName}
                  </p>
                </Col>
              )}
              {agreement?.date && (
                <Col className="panel-details" span={2}>
                  <p className="panel-details--title">Date</p>
                  <p className="panel-details--value">{agreement?.date}</p>
                </Col>
              )}
              {agreement?.statusName && (
                <Col className="panel-details" span={2.5}>
                  <p className="panel-details--title">Status</p>
                </Col>
              )}
              {agreement?.sittingWith && (
                <Col className="panel-details" span={2}>
                  <p className="panel-details--title">Sitting With</p>
                  <p className="panel-details--value">
                    {agreement?.sittingWith}
                  </p>
                </Col>
              )}
              {!!agreement?.tier && (
                <Col className="panel-details" span={1}>
                  <p className="panel-details--title">Tier</p>
                  <p className="panel-details--value">{agreement?.tier}</p>
                </Col>
              )}
              {!!agreement?.statusProgress && (
                <Col className="panel-details" span={1}>
                  <p className="panel-details--title">Progress</p>
                  <p className="panel-details--value">
                    {agreement?.statusProgress}%
                  </p>
                </Col>
              )}
              {(agreement?.responsibleFirstName ||
                agreement?.responsibleLastName) && (
                <Col className="panel-details" span={2}>
                  <p className="panel-details--title">User responsible</p>
                  <p className="panel-details--value">
                    {(agreement?.responsibleFirstName || "") +
                      " " +
                      (agreement?.responsibleLastName || "")}
                  </p>
                </Col>
              )}
            </Row>
          </Panel>
        </Collapse>
      </Col>
      <Col span={24}>
        <Row className="document-details--body">
          <Col span={18}>
            <Row>
              <Row className="document-details--body-actions-wrapper">
                <Col span={24} className="document-details--body-actions">
                  {/* {isAdmin && (
                  <ButtonComponent
                    type="primary"
                    loading={markCompleteLoading}
                    onClick={markAgreementAsCompleted}
                  >
                    Mark Completed
                  </ButtonComponent>
                )} */}
                  <div />
                  <PDFSearch searchPluginInstance={searchPluginInstance} />
                  {isAdmin && activeTabKey === "1" && (
                    <Row className="document-details--body-actions-buttons">
                      <ButtonComponent
                        type="default"
                        onClick={() => setShowImportKT(true)}
                      >
                        Import KT
                      </ButtonComponent>
                      <ButtonComponent
                        type="default"
                        onClick={() => setShowDiscard(true)}
                      >
                        Discard
                      </ButtonComponent>
                    </Row>
                  )}
                </Col>
                <Col span={24} className="document-details--body-actions-tab">
                  <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
                    <TabPane tab="Original Agreement" key="1"></TabPane>
                    {amendments?.map(({ id, name }, index) => (
                      <TabPane key={id} tab={name} />
                    ))}
                  </Tabs>
                </Col>
              </Row>
              <Col
                span={24}
                className={`document-details--body-document toolbar-less`}
              >
                <Document
                  documentURL={
                    activeTabKey === "1"
                      ? agreement?.documentUrl ?? ""
                      : amendments?.find(
                          (amendment) =>
                            amendment?.id?.toString() === activeTabKey
                        )?.documentUrl ?? ""
                  }
                  notes={notes ?? []}
                  highlightPluginInstance={highlightPluginInstance}
                  jumpToHighlightArea={jumpToHighlightArea}
                  searchPluginInstance={searchPluginInstance}
                  onDocumentLoadSuccess={()=>setDocumentLoaded(true)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={6} className="document-details--body-keyterms-toolbar">
            <p className="document-details--body-keyterms-toolbar--title">
              Key Terms
            </p>
            {/* <Input
              placeholder="Search"
              suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
            /> */}
            {keyTermToolbarGenerator()}
          </Col>
        </Row>
      </Col>
      <ConfirmationAlert
        closeHandler={() => setShowDiscard(false)}
        visible={showDiscard}
        type="delete"
        deleteHandler={discardAgreement}
        cancelHandler={() => setShowDiscard(false)}
        title="Are you sure want to discard"
        description="All the key term entries, sections and tags will be removed"
      />
      <UIModal
        className="import-kt__modal"
        visible={showImportKT}
        closeModal={() => {
          setShowImportKT(false);
        }}
        title="Import Key Terms"
        submitProps={{
          text: "Import KT",
          clickHandler: () => {
            handleImportKTAgreements();
          },
          disabled: false,
          loading: false,
        }}
        cancelProps={{
          text: "Cancel",
          clickHandler: () => {
            setShowImportKT(false);
          },
        }}
      >
        <Fragment>
          <Row wrap={false}>
            {agreement?.companyName && (
              <Col className="panel-details" span={3}>
                <p className="panel-details--title">HFund</p>
                <p className="panel-details--value">{agreement?.companyName}</p>
              </Col>
            )}
            {agreement?.fundName && (
              <Col className="panel-details" span={3}>
                <p className="panel-details--title">Fund Name</p>
                <p className="panel-details--value">{agreement?.fundName}</p>
              </Col>
            )}
            {agreement?.cpName && (
              <Col className="panel-details" span={3}>
                <p className="panel-details--title">CP Name</p>
                <p className="panel-details--value">{agreement?.cpName}</p>
              </Col>
            )}
            {agreement?.name && (
              <Col className="panel-details" span={3}>
                <p className="panel-details--title">Agreement Name</p>
                <p className="panel-details--value">{agreement?.name}</p>
              </Col>
            )}
            {(agreement?.agreementType?.name ||
              agreement?.agreementTypeName) && (
              <Col className="panel-details" span={2}>
                <p className="panel-details--title">Type</p>
                <p className="panel-details--value">
                  {agreement?.agreementType?.name ||
                    agreement?.agreementTypeName}
                </p>
              </Col>
            )}
            {agreement?.date && (
              <Col className="panel-details" span={3}>
                <p className="panel-details--title">Date</p>
                <p className="panel-details--value">
                  {moment(agreement?.date).format("DD, MMM YYYY")}
                </p>
              </Col>
            )}
          </Row>
          <Row className="search__container">
            <Col sm={24} className="search--control mb-5">
              <SearchInput onSearch={handleSearchImportableKTAgreements} />
            </Col>
          </Row>
          <TableComponent
            className="sort-remover"
            pagination={false}
            title=""
            columns={[
              {
                title: "",
                key: "action",
                width: 50,
                render: (text: string, record: Agreement) => (
                  <Radio
                    checked={record?.id === selectedKtAgreementId}
                    onClick={() => setSelectedKtAgreementId(record?.id)}
                  />
                ),
              },
              {
                title: "FUND",
                dataIndex: "fundName",
                key: "fundName",
                width: 400,
              },
              {
                title: "CP NAME",
                dataIndex: "cpName",
                key: "cpName",
                width: 400,
              },
              {
                title: "AGREEMENET NAME",
                dataIndex: "name",
                key: "name",
                width: 400,
              },
              {
                title: "DATE",
                dataIndex: "date",
                key: "date",
                width: 200,
              },
            ]}
            data={ktImportableAgreements}
            showSearch={false}
            showAdd={false}
          ></TableComponent>
        </Fragment>
      </UIModal>
    </Row>
  );
};

export default PDFContainer(DocumentDetails);
